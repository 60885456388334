/******************************* 
          RESET
        *******************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/******************************* 
          --COLORS – LIGHT & DARK MODE
        *******************************/
:root {
  --brand-default: var(--colors-purple-medium);
  --brand-lighter: var(--colors-purple-lighter);
  --brand-light: var(--colors-purple-light);
  --brand-strong: var(--colors-purple-dark);
  --success-default: var(--colors-green-medium);
  --info-default: var(--colors-blue-medium);
  --error-default: var(--colors-red-medium);
  --content-strong: var(--colors-grey-darkest);
  --content-medium: var(--colors-grey-medium);
  --content-light: var(--colors-grey-light);
  --border-light: var(--colors-grey-light);
  --background-strong: var(--colors-white);
  --background-default: var(--colors-grey-lighter);
  --background-medium: var(--colors-grey-light);
  --background-light: var(--colors-grey-light);

  --content-darker-three: var(--colors-grey-darker3);
  --content-darker-three-tint: var(--colors-tint-grey.darker3);

  --background-lightest: var(--colors-grey-lightest);
}
.darkMode {
  --brand-default: var(--colors-purple-medium);
  --brand-light: var(--colors-purple-dark);
  --brand-strong: var(--colors-purple-light);
  --success-default: var(--colors-green-medium);
  --info-default: var(--colors-blue-medium);
  --error-default: var(--colors-red-medium);
  --content-strong: var(--colors-white);
  --content-medium: var(--colors-grey-medium);
  --content-light: var(--colors-grey-dark);
  --border-light: var(--colors-grey-dark);
  --background-strong: var(--colors-grey-darkest);
  --background-default: var(--colors-grey-darker);
  --background-medium: var(--colors-grey-darker);
  --background-light: var(--colors-grey-dark);

  --background-lightest: var(--colors-grey-darker2);
}

/******************************* 
          BASICS
        *******************************/
* {
  box-sizing: border-box;
}
html {
  font-size: 100%;
}
body {
  background: var(--background-strong);
  color: var(--content-strong);
  font-family: var(--text-family);
  line-height: 1.3;
}
button {
  font-family: var(--text-family);
}
h2 {
  font-size: var(--text-label-medium-size);
  font-weight: var(--text-label-weight);
  padding: 0.5em 0;
}
p {
  margin-bottom: 1em;
}

a {
  font-weight: 500;
  text-decoration: none;
  color: var(--brand-default);
}
a:hover {
  text-decoration: underline;
  color: var(--brand-default);
}
a:focus {
  box-shadow: var(--defaults-focus-outline);
}
input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
select,
textarea {
  font-size: var(--text-paragraph-medium-size);
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 var(--mod-1-point-5-multiplier);
  box-sizing: border-box;
  font-family: var(--text-family);
  border: 2px solid var(--border-light);
  border-radius: var(--defaults-borderRadius-small);
  background: var(--background-strong);
}
input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='number']:focus,
select:focus,
textarea:focus {
  border-color: var(--brand-default);
}
input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder,
input[type='number']::placeholder,
select::placeholder,
textarea::placeholder {
  color: var(--content-medium);
}
textarea {
  height: 10em;
}
select {
  padding: 0 var(--mod-1-point-5-multiplier);
  height: 40px;
  appearance: none;
  background: url('/images/chevronDown.svg') var(--background-strong);
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
}
::selection {
  background-color: var(--colors-tint-purple-dark);
}
u {
  text-decoration: underline;
}
strong,
b {
  font-weight: bold;
}
em,
i {
  font-style: italic;
}

/******************************* 
          Typography
        *******************************/
.paragraph-small {
  font-size: var(--text-paragraph-small-size);
  font-weight: var(--text-paragraph-weight);
}
.paragraph-medium {
  font-size: var(--text-paragraph-medium-size);
  font-weight: var(--text-paragraph-weight);
}
.label-small {
  font-size: var(--text-label-small-size);
  font-weight: var(--text-label-weight);
}
.label-medium {
  font-size: var(--text-label-medium-size);
  font-weight: var(--text-label-weight);
}

/******************************* 
          Layout
        *******************************/
:root {
  --layout-max-width: 1760px;
}
