:root {
  --breakpoints-smallest: 300px;
  --breakpoints-small: 767px;
  --breakpoints-medium: 1023px;
  --breakpoints-large: 1120px;
  /* Defaults */
  --defaults-animation-time: 0.3s;
  --defaults-header-size: 78px;
  --defaults-borderRadius-veryTiny: 5px;
  --defaults-borderRadius-tiny: 6px;
  --defaults-borderRadius-small: 8px;
  --defaults-borderRadius-medium: 16px;
  --defaults-borderRadius-large: 24px;
  --defaults-shadow-medium-no-bottom-shadow: 0px -7px 16px -1px
      rgba(66, 0, 174, 0.08),
    0px -6px 8px -3px rgba(66, 0, 174, 0.08),
    0px -6px 4px -2px rgba(66, 0, 174, 0.08);
  --defaults-shadow-medium: 0px 4px 16px rgba(66, 0, 174, 0.08),
    0px 2px 8px rgba(66, 0, 174, 0.08), 0px 1px 4px rgba(66, 0, 174, 0.08);
  --defaults-shadow-large: 0px 32px 64px rgba(66, 0, 174, 0.08),
    0px 8px 32px rgba(66, 0, 174, 0.08), 0px 4px 16px rgba(66, 0, 174, 0.08),
    0px 2px 8px rgba(66, 0, 174, 0.08), 0px 1px 4px rgba(66, 0, 174, 0.08);
  --defaults-shadow-red-medium: 0px 4px 16px rgba(243, 99, 99, 0.08),
    0px 2px 8px rgba(243, 99, 99, 0.08), 0px 1px 4px rgba(243, 99, 99, 0.08);
  --defaults-focus-outline: 0 0 0 3px var(--background-default),
    0 0 0 5px var(--brand-default);

  /* Typography */
  --text-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --text-label-weight: 600;
  --text-label-medium-size: 16px;
  --text-label-medium-line-height: 20px;
  --text-label-small-size: 13px;
  --text-label-small-line-height: 16px;
  --text-paragraph-weight: 400;
  --text-paragraph-medium-size: 14px;
  --text-paragraph-medium-line-height: 18px;
  --text-paragraph-small-size: 12px;
  --text-paragraph-small-line-height: 18px;

  /* Spacing */
  --mod-0-point-5-multiplier: 4px;
  --mod-0-point-75-multiplier: 6px;
  --mod-1-multiplier: 8px;
  --mod-1-point-25-multiplier: 10px;
  --mod-1-point-5-multiplier: 12px;
  --mod-2-multiplier: 16px;
  --mod-2-point-5-multiplier: 20px;
  --mod-3-multiplier: 24px;
  --mod-3-point-5-multiplier: 28px;
  --mod-4-multiplier: 32px;
  --mod-5-multiplier: 40px;
  --mod-6-multiplier: 48px;
  --mod-7-multiplier: 56px;
  --mod-8-multiplier: 64px;
  --mod-9-multiplier: 72px;
  --mod-10-multiplier: 80px;
  --mod-11-multiplier: 88px;
  --mod-12-multiplier: 96px;
  --mod-18-multiplier: 144px;

  /* Colors */
  --colors-white: #fff;
  --colors-tint-purple-light: rgba(140, 78, 241, 0.1);
  --colors-tint-purple-dark: rgba(140, 78, 241, 0.8);
  --colors-tint-red: rgba(243, 99, 99, 0.1);
  --colors-tint-grey-darker3: rgba(77, 90, 97, 0.7);
  --colors-grey-lightest: #f7f6f9;
  --colors-grey-lighter: #f1f0f5;
  --colors-grey-light: #e3e1ea;
  --colors-grey-medium: #aca4c1;
  --colors-grey-dark: #8277a2;
  --colors-grey-darker: #353046;
  --colors-grey-darker2: #2d273a;
  --colors-grey-darker3: #4d5a61;
  --colors-grey-darkest: #211d2b;
  --colors-greyish-purple: #6c6483;
  --colors-lavender-pale: #f9f8ff;
  --colors-lavender-lighter: #ede3ff;
  --colors-lavender-light: #d0b0ff;
  --colors-purple-lighter: #f3edfe;
  --colors-purple-light: #b080ff;
  --colors-purple-medium: #8c4ef1;
  --colors-purple-dark: #7728f9;
  --colors-red-medium: #f36363;
  --colors-red-light: #fef0f0;
  --colors-orange-medium: #f66436;
  --colors-orange-light: #feefeb;
  --colors-yellow-medium: #ca9d00;
  --colors-yellow-light: #faf5e5;
  --colors-green-medium: #9eb801;
  --colors-green-light: #f5f8e6;
  --colors-teal-medium: #33b787;
  --colors-teal-light: #ebf8f3;
  --colors-turquoise-medium: #25d3be;
  --colors-turquoise-light: #e9fbf9;
  --colors-blue-medium: #4ea3f1;
  --colors-blue-light: #edf6fe;
  --colors-blue-doc: #4e55f1;
  --colors-navy-medium: #4e55f1;
  --colors-navy-light: #edeefe;

  /* Misc */
  --alert-fade-delay: 2000ms;
}
