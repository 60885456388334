.informationModalOverlay {
  background-color: rgba(22, 27, 29, 0.5);
}
.informationModal {
  padding: 50px;
  padding-bottom: 36px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  max-width: 620px;
}
.informationModal h2 {
  margin-bottom: 20px;
  padding-top: 0;
}
.informationModal p {
  font-size: var(--text-paragraph-medium-size);
  color: var(--colors-greyish-purple);
  line-height: 1.6;
  font-weight: 400;
  text-align: left;
  overflow-wrap: break-word;
}
.informationModal ul {
  margin-left: 16px;
  font-size: var(--text-paragraph-medium-size);
  color: var(--colors-greyish-purple);
  list-style-type: disc;
  margin-bottom: 16px;
}
.informationModal li {
  padding: 5px 0;
}
