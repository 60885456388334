.wordAppModalOverlay {
  background-color: rgba(22, 27, 29, 0.5);
}
.wordAppModal {
  padding: 50px;
  padding-bottom: 36px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  max-width: 620px;
}
.wordAppModal h2 {
  margin-bottom: 20px;
  padding-top: 0;
}
.wordAppModal p {
  font-size: var(--text-paragraph-medium-size);
  color: var(--colors-greyish-purple);
  line-height: 1.6;
  font-weight: 400;
  text-align: justify;
}
.csl-entry {
  word-break: break-word;
  display: inline;
}
.csl-left-margin {
  float: left;
}
.csl-right-inline {
  margin-left: 35px;
}
.inline-csl-entry,
.bibliography-csl-entry {
  display: inline-block;
  margin: 0px;
  line-height: 1.4em;
  width: -moz-available;
  padding-left: 0;
  text-indent: 0;
}
.csl-entry-container {
  margin-bottom: 5px;
}
