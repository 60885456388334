:root {
  --color-white: #fff;
  --color-grey-lightest: #f7f6f9;
  --color-grey-lighter: #f1f0f5;
  --color-grey-light: #e3e1ea;
  --color-grey-medium: #aca4c1;
  --color-grey-dark: #8277a2;
  --color-grey-darker: #353046;
  --color-grey-darker2: #2d273a;
  --color-grey-darkest: #211d2b;
  --color-grey-alternate: #4d5a61;
  --color-purple-lighter: #f3edfe;
  --color-purple-light: #b080ff;
  --color-purple-medium: #8c4ef1;
  --color-purple-dark: #7728f9;
  --color-red-medium: #f36363;
  --color-red-light: #fef0f0;
  --color-orange-medium: #f66436;
  --color-orange-light: #feefeb;
  --color-yellow-medium: #ca9d00;
  --color-yellow-light: #faf5e5;
  --color-green-medium: #9eb801;
  --color-green-light: #f5f8e6;
  --color-teal-medium: #33b787;
  --color-teal-light: #ebf8f3;
  --color-turquoise-medium: #25d3be;
  --color-turquoise-light: #e9fbf9;
  --color-blue-medium: #4ea3f1;
  --color-blue-light: #edf6fe;
  --color-navy-medium: #4e55f1;
  --color-navy-light: #edeefe;

  --color-tint-purple-light: rgba(140, 78, 241, 0.1);
  --color-tint-purple-dark: rgba(140, 78, 241, 0.8);
  --color-tint-red: rgba(243, 99, 99, 0.1);
  --color-tint-grey-alternate: rgba(77, 90, 97, 0.7);
}

.container {
  max-width: var(--layout-max-width);
  margin: 0 auto;
}
